<script lang="ts" setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { Icon } from "@iconify/vue";

const {
  debounce = 400,
  routeQueryKey = "search",
  loading = false
} = defineProps<{
  debounce?: number;
  routeQueryKey?: string;
  loading?: boolean;
  class?: string;
}>();

const emit = defineEmits<{
  (e: "termChanged", text: string): void;
}>();

const route = useRoute();
const router = useRouter();

const term = ref("");
const inputRef = ref<HTMLElement | null>(null);

// Initialize the term with the query parameter if it exists
if (route.query[routeQueryKey]) {
  term.value = (route.query[routeQueryKey] as string) || "";
}
emit("termChanged", term.value);

watchDebounced(
  term,
  (value) => {
    // Emit the termChanged event
    emit("termChanged", value);

    // Update the URL query parameter
    const query = { ...route.query };
    if (term.value === "") {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete query[routeQueryKey];
    } else {
      query[routeQueryKey] = term.value;
    }
    router.replace({ query });
  },
  { debounce }
);

watch(
  () => route.query[routeQueryKey],
  (newSearch) => {
    if (newSearch !== term.value) {
      // if query parameter is undefined, set term to empty string
      term.value = (newSearch as string) || "";
    }
  }
);

// onMounted(() => {
//   inputRef.value?.focus();
// });
</script>

<template>
  <div class="relative flex items-center overflow-hidden group" :class="$props.class">
    <Icon
      :icon="loading ? 'lucide-loader-circle' : 'lucide-search'"
      :class="cn('size-6 absolute left-3 group-focus-within:text-online', { 'animate-spin': loading })"
    />
    <input
      ref="inputRef"
      v-model="term"
      :placeholder="$t('search.placeholder')"
      :class="
        cn('pl-12 pr-0 w-full h-full transition-all text-base', 'focus:text-lg bg-transparent focus:ring-0')
      "
    />
    <BasicButton v-if="term" icon="lucide-x" size="sm" @click="term = ''" />
  </div>
</template>
